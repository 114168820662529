import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import factcheck from '../img/fact-check.png'

const Trasparenza = () => {
    return (
        <>
            <Navigation/>
            <Container id="verifica">
                <p className='titolo mb-5'>Processo di verifica delle informazioni <br/> su <span className='font-italic text-primary'>help-ukrainians.org</span></p>
                <p className=''> Scritto da Pietro Lechthaler e <a style={{textDecoration:"none"}} href="https://www.raffaelecrocco.com/" target="_blank">Raffaele Crocco</a> </p>
                <Row>
                    <Col md="5">
                        <p>
                            Per incoraggiare e mantenere la fiducia tra gli utenti all'interno di <span className='font-italic text-primary'>help-ukrainians.org</span>, le associazioni o gli enti di beneficenza dovranno confermare la loro identità in modo che la loro attività venga pubblicata.
                        </p>
                        <p>
                            Verrà chiesto di compilare un form Google Moduli che richiederà le seguenti informazioni:
                            <ul>
                                <li>
                                    <b>Sito ufficiale:</b> fornire un link a un sito ufficiale che faccia riferimento all’organizzazione e uno all’evento da pubblicare.
                                </li>
                                <li>
                                    <b>Indirizzo email e telefono referente:</b> fornire un indirizzo email ufficiale e un numero di telefono di un referente dell’associazione.
                                </li>
                                <li>
                                    <b>Indirizzo sede associazione/ente beneficenza:</b> fornire un indirizzo dell’associazione e/o l’indirizzo di un eventuale punto di raccolta materiali.
                                </li>

                            </ul>
                            <b>Processo di verifica</b> <br/>
                            I nostri utenti, o le associazioni coinvolte,  inviano le segnalazioni via apposito form Google Moduli.
                            Le segnalazioni vengono ricevute e inoltrate al team, che procede all’analisi. Le analisi richiedono tempi diversi a seconda del tipo di iniziativa. <br/>

                            <br/>Presa in carico l’iniziativa, il team procede nella verifica delle informazioni in essa contenute. 
                            Tale verifica si svolge con il confronto tra le fonti in base alle parole chiave. Quando non è possibile giungere all’origine dell’iniziativa attraverso le testate ufficiali si procede ulteriormente con la verifica presso i siti istituzionali (e loro pagine social), enti, aziende, esercizi commerciali eventualmente coinvolti nell’iniziativa e, quando necessario, presso le forze dell’ordine.


                        </p>
                    </Col>
                    <Col md="7" className='d-flex justify-content-center'>
                        <img src={factcheck} style={{height:"44vh"}}/>
                    </Col>
                </Row>
            </Container>
            <Container id="gestione" className='mt-5'>
                
                <p className='titolo mb-5'>Chi gestisce <span className='font-italic text-primary'>help-ukrainians.org</span>?</p>
                <p className=''> Scritto da Pietro Lechthaler e <a style={{textDecoration:"none"}} href="https://www.raffaelecrocco.com/" target="_blank">Raffaele Crocco</a> </p>
                <Row>
                    <Col md="9">
                        <p>
                            <b>Il progetto</b> <br/>
                            <span className='font-italic text-primary'>Help-ukrainians.org</span> è un servizio gratuito che permette la condivisione di iniziative benefiche al supporto del  popolo ucraino, duramente colpito dalla guerra.
                            Il progetto si concentra su raccolta beni di prima necessita, raccolte fondi e petizioni.
                            Il sito è online da marzo 2022 e la mission si sintetizza dietro lo slogan “l’Ucraina ha bisogno di te”.
                        </p>
                        <p>
                            <b>Il team</b> <br/>
                            Help-ukrainians.org è stato fondato da Pietro Lechthaler e Raffaele Crocco, entrambi studenti di Ingegneria Informatica all’Università di Trento. 
                            Il team è formato da liberi cittadini nessuno dei quali legato ad alcun movimento né partito politico. Help-ukrainians.org non è affiliata ad alcuna corrente politica né ad alcun personaggio pubblico.
                            Il team non riceve compensi da alcun partito né da alcun politico. Il lavoro si basa esclusivamente sulle segnalazioni degli utenti. 
                        </p>
                        <p>
                            <b>Come ci finanziamo</b> <br/>
                            Come ci finanziamo
                            Il servizio Help-ukrainians.org è finanziato da donazioni spontanee degli utenti su PayPal. Le offerte ricoprono le spese di gestione del sito e l’attrezzatura necessaria al team per portare avanti il progetto.
                        </p>
                        <p>
                            <b>Contatti</b> <br/>
                            Vuoi entrare nel nostro team? Vuoi suggerirci delle modifiche?
                            <br/>Sentiti libero di scriverci un email a helpukrainiansita@gmail.com.                   
                        </p>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </>
    );
};

export default Trasparenza;