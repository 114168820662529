import React from 'react';
import Navigation from '../components/Navigation';

import Beni from '../components/Beni';
import Hero from '../components/Hero';
import Fondi from '../components/Fondi';
import Footer from '../components/Footer';
import Petizioni from '../components/Petizioni';

const Home = () => {
    return (
        <>
            <div >
                <Navigation/>
                <Hero/>
                <Beni/>
                <Fondi/>
                <Petizioni/>
                <Footer/>
            </div>
        </>
    );
};

export default Home;