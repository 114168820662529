import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import InfoCard from './InfoCard';

import data from '../data/fondi.json'
import { HashLink as Link } from 'react-router-hash-link';

const Fondi = () => {
    console.log(data[0])
    return (
        <div id="fondi" className="d-flex align-items-center" style={{minHeight:"80vh"}}>
            <Container>
                <div className="my-5">
                    <h6 className='fw-bold text-primary'>DONAZIONI SICURE</h6>
                    <span className="titolo">Raccolte fondi</span>
                    <p className='mt-4'>
                        Avviare un crowdfunding sociale verso un’organizzazione nota e autorevole come quelle proposte è il modo migliore per fornire un rapido supporto al popolo ucraino.	
                    </p>
                </div>
                
                
                <Row>
                    <Col xs="12" md="10">
                    <Row className="g-4">
                        <InfoCard title={data[0].nome} subtitle={data[0].tipologia} text={data[0].descrizione} size={"20rem"} button={data[0].link}/>
                        <InfoCard title={data[1].nome} subtitle={data[1].tipologia} text={data[1].descrizione} size={"20rem"} button={data[1].link}/>
                        <InfoCard title={data[2].nome} subtitle={data[2].tipologia} text={data[2].descrizione} size={"20rem"} button={data[2].link}/>   
                    </Row>
                    </Col>
                    <Col xs="12" md="2" className="d-flex justify-content-center align-items-center">
                        <div>
                            <p className='mb-2 d-none d-md-block'>Scopri le altre raccolte <br/>fondi della tua zona.</p>
                            <Link to="/raccolte#">
                                <Button className="fw-bold mb-3" variant='warning'> Altre raccolte fondi</Button>
                            </Link> <br/>
                            <Link className='text-muted' to="/trasparenza#verifica">Come vengono verificate?</Link>
                        </div>
                    </Col> 
                </Row>
                
            </Container> 
        </div>
    );
};

export default Fondi;