import React from 'react';
import InfoCard from './InfoCard';

const Risultati = (props) => {
    return (
        <>
          {props.results.map((item) => (
            <InfoCard
                title={item.zona.area} 
                subtitle={item.nome} 
                link={item.zona.posizione} 
                namelink={item.zona.indirizzo} 
                text={item.materiali} 
                size={"20rem"} 
                button={item.contatti} 
                contact={item.numero}
            />       
          ))}
        </>
    );
};

export default Risultati;