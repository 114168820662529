import React from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import logo from '../img/logoblue.png'
import { HashLink as Link } from 'react-router-hash-link';

const Footer = () => {
    return (
        <>
            
                <Row style={{height:"20vh", width:"100%", margin:"0", marginTop:"160px"}}>
                    <Col xs="12" style={{backgroundColor:"#005BBB"}}>
                        <Container>
                            <Row>
                                <Col className='d-flex justify-content-center'>
                                    <img src={logo} style={{height:"250px"}}/>
                                </Col>
                                <Col className='d-flex align-items-center'>
                                    <p className='fw-bold text-light'>
                                        <span className='text-warning'>Help-ukrainians.org</span> è un servizio gratuito a disposizione dei cittadini che permette la condivisione di iniziative benefiche al supporto del popolo ucraino.
                                        <br/><br/>Ideato, fondato e gestito da Pietro Lechthaler e <a style={{color:"#fff"}} href="https://www.raffaelecrocco.com/" target="_blank">Raffaele Crocco</a>.
                                    </p>
                                </Col>
                            </Row>                        
                        </Container>
                    </Col>
                    <Col style={{backgroundColor:"#FFD500", minHeight: "100px"}}>
                        <Container style={{height:"100%"}}>
                            <Row className="py-4" style={{minHeight:"12vh"}} >
                                <Col xs="12" md="2" className="d-flex align-items-center justify-content-center fw-bold">Help-ukrainians.org</Col>
                                <Col xs="12" md="2"  className="d-flex align-items-center justify-content-center">
                                    <Link to="/#beni" style={{textDecoration:"none"}}> Beni di prima necessità </Link>
                                </Col>
                                <Col xs="12" md="2" className="d-flex align-items-center justify-content-center">
                                    <Link to="/#fondi" style={{textDecoration:"none"}}> Raccolte Fondi </Link>
                                </Col>
                                <Col xs="12" md="2" className="d-flex align-items-center justify-content-center">
                                    <Link to="/#petizioni" style={{textDecoration:"none"}}> Petizioni </Link>
                                </Col>
                                <Col xs="12" md="2" className="d-flex align-items-center justify-content-center">
                                    <Link to="/trasparenza#" style={{textDecoration:"none"}}> Trasparenza </Link>
                                </Col>
                                <Col  xs="12" md="2" className="d-flex align-items-center justify-content-center">
                                    <Button size="sm" variant='outline-dark' href="https://www.buymeacoffee.com/helpukrainians" target="_blank">Sostieni il progetto</Button>
                                </Col>
                            </Row>


                            
                            {/* <Link to="/#fondi" style={{textDecoration:"none"}}>
                                <Nav.Link href="#def">Raccolte fondi</Nav.Link>
                            </Link>
                            <Link to="/#petizioni" style={{textDecoration:"none"}}>
                                <Nav.Link href="#def">Petizioni</Nav.Link>
                            </Link>
                            <NavDropdown title="Trasparenza" id="collasible-nav-dropdown">
                                <Link to="/trasparenza#verifica" style={{textDecoration:"none"}}>
                                    <NavDropdown.Item href="#def">Verifica delle informazioni</NavDropdown.Item>
                                </Link>
                                <Link to="/trasparenza#gestione" style={{textDecoration:"none"}}>
                                    <NavDropdown.Item href="#def">Chi gestisce la pagina</NavDropdown.Item>
                                </Link> */}
                        </Container> 
                    </Col>
                </Row>
            
        </>
        
    );
};

export default Footer;