import React, { useState, useMemo } from "react";
import { Col, Container, Row, Button } from 'react-bootstrap';
import Select from "react-select";
import Footer from '../components/Footer';
import InfoCard from '../components/InfoCard';
import Navigation from '../components/Navigation';
import Risultati from "../components/Risultati";
import data from '../data/associazioni.json'




const Associazioni = () => {

    
    //create a vector of topic
    const topics = data.map((item) => item.zona.area);
    const topicArray = [...new Set(topics)];
    //converting the topics array in a useful structure
    const topicOptions = [
        {
            value: "Tutte le zone",
            label: "Tutte le zone",
        },
    ];
    for (const topicItem in topicArray) {
        topicOptions.push({
            value: topicArray[topicItem],
            label: topicArray[topicItem],
        });
    }

    //create a state for area
    const [area, setArea] = useState(["Tutte le zone"]);

    //handle change of state selecting a new area
    const handleChange = (e) => {
        const selectedArea = [];
        for (var item in e) {
        selectedArea.push(e[item].value);
        }
        setArea(selectedArea);
    };

    //to check easly later
    const areaStateValues = [];
    for (const item in area) {
        areaStateValues.push(area[item]);
    }

    const results = useMemo(() => {
        return data.filter((item) => {
          if (areaStateValues.includes("Tutte le zone")) {
            return true;  
          }else {
            if (areaStateValues.includes(item.zona.area)) {
              return true;
            }else{
                return false;
            }
          }
        });
    }, [areaStateValues]);
    




    return (
        <>
            <Navigation/>
            <Container>
                <p className='titolo mb-5'>Associazioni per la donazione <br/> beni di <span className='text-primary'>prima necessità</span></p>
                <div style={{width:"300px"}} className='mb-5'>
                    <Select
                        // defaultValue={topicOptions[0]}
                        isMulti
                        placeholder="Cerca secondo la tua zona"
                        options={topicOptions}
                        onChange={handleChange}
                    /> 
                </div>
                
                <Row className="g-4">
                    <Risultati results={results} /> 
                </Row>

                <p style={{marginTop:"50px"}}>Vuoi segnalarci un'associazione per farla comparire nell'elenco? <u>Utilizza il pulsante qui sotto.</u></p>
                <a href="https://forms.gle/7yzBn7kRWm9svG4d7" target="_blank">
                    <Button variant="warning" style={{borderRadius: "10px", height: "48px",  fontSize: "14px"}} className="fw-bold">Segnala un'associazione?</Button>
                </a>
            </Container>
            <Footer/>
        </>
    );
};

export default Associazioni;