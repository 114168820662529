import React from 'react';
import { Card, Button } from 'react-bootstrap';

const InfoCard = (props) => {
    return (
        <>
            <Card border="secondary" style={{ width: props.size, borderRadius: "24px"}} className="m-3">
                <Card.Body>
                    <Card.Title className="mb-3 fs-big" style={{fontSize:"25px"}}>{props.title}</Card.Title>
                    <Card.Subtitle className="mb-2">{props.subtitle}</Card.Subtitle>
                    {
                        props.link
                        ?
                        <Card.Subtitle>
                            <a href={props.link} target="_blank" style={{fontSize:"12px"}}>{props.namelink}</a>
                        </Card.Subtitle>
                        :
                        ""
                    }
                    
                    <Card.Text className="mt-3">
                        {props.text}
                    </Card.Text>
                    <div className="text-center">
                        <Button className="my-2" variant="primary"  style={{width:"90%", borderRadius: "10px"}} href={props.button} target="_blank">Scopri come aiutare</Button>
                        { 
                            props.contact
                            ?
                            <a href={props.contact}>Contatta</a>
                            :
                            ""
                        }
                        
                    </div>
                    
                </Card.Body>
            </Card>
        </>
    );
};

export default InfoCard;