import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../img/logo.jpg'
import { RWebShare } from "react-web-share";

const Navigation = () => {
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="none" variant="light">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={logo} style={{height:"100px"}}/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link to="/#beni" style={{textDecoration:"none"}}>
                                <Nav.Link href="#def">Beni di prima necessità</Nav.Link>
                            </Link>
                            <Link to="/#fondi" style={{textDecoration:"none"}}>
                                <Nav.Link href="#def">Raccolte fondi</Nav.Link>
                            </Link>
                            <Link to="/#petizioni" style={{textDecoration:"none"}}>
                                <Nav.Link href="#def">Petizioni</Nav.Link>
                            </Link>
                            <NavDropdown title="Trasparenza" id="collasible-nav-dropdown">
                                <Link to="/trasparenza#verifica" style={{textDecoration:"none"}}>
                                    <NavDropdown.Item href="#def">Verifica delle informazioni</NavDropdown.Item>
                                </Link>
                                <Link to="/trasparenza#gestione" style={{textDecoration:"none"}}>
                                    <NavDropdown.Item href="#def">Chi gestisce la pagina</NavDropdown.Item>
                                </Link>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <RWebShare
                                data={{
                                text: "Help-ukrainians è un servizio a disposizione dei cittadini per condividere iniziative al supporto del popolo ucraino.",
                                url: "https://help-ukrainians.org",
                                title: "Condividi e sostienici!"
                                }}
                                onClick={() => console.info("share successful!")}
                            >
                                <Nav.Link href="#deets">Aiutaci a spargere la voce</Nav.Link>
                            </RWebShare>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default Navigation;