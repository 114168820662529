import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import InfoCard from './InfoCard';

import data from '../data/petizioni.json'
import { Link } from 'react-router-dom';

const Petizioni = () => {
    console.log(data[0])
    return (
        <div id="petizioni" className="d-flex align-items-center" style={{minHeight:"80vh"}}>
            <Container>
                <div className="my-5">
                    <h6 className='fw-bold text-primary'>CHANGE.ORG</h6>
                    <span className="titolo">Firma una petizione</span>
                    <p className='mt-4'>
                        Un gran numero di firme su una petizione ci aiuta a mostrare alle autorità politiche, che migliaia di persone stanno supportando delle richieste. <br/> Senza il tuo sostegno, la guerra non può finire.	
                    </p>
                </div>
                
                
                <Row className="g-4">
                    <InfoCard title={data[0].nome} subtitle={data[0].tipologia} text={data[0].descrizione} size={"21rem"} button={data[0].link}/>
                    <InfoCard title={data[1].nome} subtitle={data[1].tipologia} text={data[1].descrizione} size={"21rem"} button={data[1].link}/>
                    <InfoCard title={data[2].nome} subtitle={data[2].tipologia} text={data[2].descrizione} size={"21rem"} button={data[2].link}/>   
                </Row>

                            
                
            </Container> 
        </div>
    );
};

export default Petizioni;