import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import InfoCard from './InfoCard';
import data from '../data/associazioni.json'
import { HashLink as Link } from 'react-router-hash-link';

const Beni = () => {
    return (
        <div id="beni" className="d-flex align-items-center" style={{minHeight:"80vh"}}>
            <Container>
                <div className="my-5">
                    <h6 className='fw-bold text-primary'>RACCOLTE VERIFICATE</h6>
                    <span className="titolo">Donazioni beni di prima necessità</span>
                    <p className='mt-4'>
                        Spesso sottovalutiamo l’effetto che una singola donazione può avere per gli altri, perché non conosciamo bene quali sono i benefici. <br/> 	Garantiamo alle famiglie e ai bambini dell’Ucraina cure mediche, acqua potabile, kit per l’igiene personale, supporto psicologico, rifugio, coperte.
                    </p>
                </div>
                
                <Row>
                    <Col xs="12" md="10">
                        <Row className="g-4">
                            <InfoCard title={data[0].zona.area} subtitle={data[0].nome} link={data[0].zona.posizione} namelink={data[0].zona.indirizzo} text={data[0].materiali} size={"20rem"} button={data[0].contatti} contact={data[0].numero}/>
                            <InfoCard title={data[1].zona.area} subtitle={data[1].nome} link={data[1].zona.posizione} namelink={data[1].zona.indirizzo} text={data[1].materiali} size={"20rem"} button={data[1].contatti} contact={data[1].numero}/>
                            <InfoCard title={data[2].zona.area} subtitle={data[2].nome} link={data[2].zona.posizione} namelink={data[2].zona.indirizzo} text={data[2].materiali} size={"20rem"} button={data[2].contatti} contact={data[2].numero}/>
                            
                        </Row>
                    </Col>
                    <Col xs="12" md="2" className="d-flex justify-content-center align-items-center">
                        <div>
                            <p className='mb-2 d-none d-md-block'>Scopri le altre iniziative <br/>segnalate dai nostri utenti.</p>
                            <Link to="/associazioni#">
                                <Button className="fw-bold mb-3" variant='warning'> Altre associazioni</Button>
                            </Link> <br/>
                            <Link className='text-muted' to="/trasparenza#verifica">Come vengono verificate?</Link>
                        </div>
                    </Col> 
                </Row>
            </Container> 
        </div>
    );
};

export default Beni;