import React from 'react';
import { Col, Container, Row, Button, Card, CardGroup } from 'react-bootstrap';
import hand from '../img/hand.png'
import uno from '../img/1.png'
import due from '../img/2.png'
import tre from '../img/3.png'
import stopwar from '../img/stop-war.svg'
import { Link } from 'react-router-dom';

const Hero = () => {
    return (
        <>
            <Container>
                <Row style={{minHeight:"55vh"}} className="px-5 mt-5">
                    <Col sm="12" lg="5" className="d-flex justify-content-center align-items-center">
                        <div>
                            <p className="tagline">HELP UKRAINIANS &times; Trentino Alto Adige</p>
                            <p className="titolo">L'Ucraina ha<br/> bisogno di te.</p>
                            <p>Informazioni utili per sostenere il popolo ucraino duramente colpito dalla guerra con donazioni a enti verificati.</p>
                            <Row className="mt-4">
                                <Col xs="auto">
                                    <a href="#beni">
                                        <Button variant="warning" style={{borderRadius: "10px", width: "190px", height: "48px",  fontSize: "14px"}} className="fw-bold mb-2">Scopri come aiutare</Button>
                                    </a>
                                </Col>
                                <Col>
                                    <a href="https://forms.gle/7yzBn7kRWm9svG4d7" target="_blank">
                                        <Button variant="outline-secondary" style={{borderRadius: "10px", width: "190px", height: "48px",  fontSize: "14px"}} className="fw-bold mb-4">Sei un'associazione?</Button>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm="12" md="7" className='d-flex justify-content-end align-items-end'>
                        <img className="d-none d-lg-block" src={hand} style={{height:"54vh"}}/>
                    </Col>
                </Row>
                {/* <CardGroup className='mt-4' style={{cursor:"pointer"}}>
                    <Card border="light">
                        <div className="card-body">
                            <h5>
                                <img src={uno} className="pe-3"/>
                                Beni di prima necessità
                            </h5>
                            <p class="card-text">Scopri quali sono le associazioni migliori per donare vestiti, cibo e altre risorse a chi ne ha bisogno.</p>
                        </div>
                    </Card>
                    <Card border="light">
                        <div className="card-body">
                            <h5>
                                <img src={due} className="pe-3"/>
                                Raccolte fondi
                            </h5>
                            <p>Assicrati che ogni singolo centesimo donato arrivi a chi necessita del tuo sostegno tramite enti verificati.</p>
                        </div>
                    </Card>
                    <Card border="light">
                        <div className="card-body">
                            <h5>
                                <img src={tre} className="pe-3"/>
                                Petizioni
                            </h5>
                            <p>Scopri le petizioni più popolari e attuali sul tema e firma anche tu per il cambiamento.</p>
                        </div>
                    </Card>
                </CardGroup> */}
            </Container>
            <img src={stopwar} style={{width:"100%"}}/>
        </>
    );
};

export default Hero;