import React from "react";
//style
import "./style/App.scss"
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
//router
import {Route, BrowserRouter as Router, Routes } from "react-router-dom"
//components
import Home from './pages/Home'
import Trasparenza from "./pages/Trasparenza";
import Associazioni from "./pages/Associazioni";
import Raccolte from "./pages/Raccolte";

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/trasparenza' exact element={<Trasparenza/>} />
        <Route path='/associazioni' exact element={<Associazioni/>} />
        <Route path='/raccolte' exact element={<Raccolte/>} />
        {/* <Route render={() => <PageNotFound />}/> */}
      </Routes>
    </Router>
      
    </>
  );
}

export default App;