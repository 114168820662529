import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import InfoCard from '../components/InfoCard';
import Navigation from '../components/Navigation';
import data from '../data/fondi.json'

const Raccolte = () => {
    return (
        <>
            <Navigation/>
            <Container>
                <p className='titolo mb-5'>Raccolte fondi per donare<br/> a chi <span className='text-primary'>ne ha bisogno</span></p>
                <Row className="g-4">
                    <InfoCard title={data[0].nome} subtitle={data[0].tipologia} text={data[0].descrizione} size={"21rem"} button={data[0].link}/>
                    <InfoCard title={data[1].nome} subtitle={data[1].tipologia} text={data[1].descrizione} size={"21rem"} button={data[1].link}/>
                    <InfoCard title={data[2].nome} subtitle={data[2].tipologia} text={data[2].descrizione} size={"21rem"} button={data[2].link}/>
                    <InfoCard title={data[3].nome} subtitle={data[3].tipologia} text={data[3].descrizione} size={"21rem"} button={data[3].link}/>
                    <InfoCard title={data[4].nome} subtitle={data[4].tipologia} text={data[4].descrizione} size={"21rem"} button={data[4].link}/>
                    <InfoCard title={data[5].nome} subtitle={data[5].tipologia} text={data[5].descrizione} size={"21rem"} button={data[5].link}/>
                </Row>
                <p style={{marginTop:"50px"}}>Vuoi segnalarci una raccolta fondi per farla comparire nell'elenco? <u>Utilizza il pulsante qui sotto.</u></p>
                <a href="https://forms.gle/7yzBn7kRWm9svG4d7" target="_blank">
                    <Button variant="warning" style={{borderRadius: "10px", height: "48px",  fontSize: "14px"}} className="fw-bold">Segnala una raccolta</Button>
                </a>
            </Container>
            <Footer/>
        </>
    );
};

export default Raccolte;